import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client/core'
import { AlertController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, firstValueFrom, map } from 'rxjs'

import {
    DrivingSchool,
    MeQuery,
    MeQueryService,
    ResendEmailVerificationLinkMutation,
    ResendEmailVerificationLinkMutationService,
    RoleEnum,
    User,
    VerifyEmailInput,
    VerifyEmailMutation,
    VerifyEmailMutationService,
} from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'
import { AuthService } from '@app/services'

@Injectable({
    providedIn: 'root',
})
export class UserService {

    public user$ = new BehaviorSubject<Partial<User>>(null)
    public primaryRoleChange$ = new BehaviorSubject<RoleEnum>(null)
    public initialized = false
    public userRequiresEmailVerification = false

    private user: Partial<User>
    private primaryRole: RoleEnum

    constructor(
        private readonly authService: AuthService,
        private readonly apiHelperService: ApiHelperService,
        private readonly alertController: AlertController,
        private readonly meQueryService: MeQueryService,
        private readonly resendEmailVerificationLinkService: ResendEmailVerificationLinkMutationService,
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly verifyEmailMutationService: VerifyEmailMutationService,
    ) {
        this.authService.loginStateChanged$.subscribe(async (isLoggedIn: boolean) => {
            if (! isLoggedIn) {
                this.user = null
                this.user$.next(null)
            }
        })
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            return
        }

        await this.getUser()

        this.initialized = true
    }

    public async getUser(cacheOptions?: CacheOptions): Promise<Partial<User> | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, 'user.me')
        const user$ = this.meQueryService.fetch(null, { fetchPolicy }).pipe(
            map(async (result: ApolloQueryResult<MeQuery>) => {
                this.user = result.data.me as Partial<User>
                /*
                 * The primary role decides which navigation is shown.
                 * At this time, we only support instructors and students.
                 */
                const previousRole = this.primaryRole
                this.primaryRole = this.user?.roles.find((userRole) =>
                    userRole.name === RoleEnum.Student,
                ) ? RoleEnum.Student : RoleEnum.Instructor
                this.user$.next(this.user)

                if (this.primaryRole !== previousRole) {
                    this.primaryRoleChange$.next(this.primaryRole)
                }

                // Redirect to instructor home if logged in as instructor and we're on the (student) home page
                if (
                    this.primaryRole === RoleEnum.Instructor
                    && this.router.url.includes('home')
                    && ! this.router.url.includes('instructor-home')
                ) {
                    await this.router.navigateByUrl('/tabs/instructor-home', { replaceUrl: true })
                }

                return this.user
            }),
        )

        try {
            return await firstValueFrom(user$)
        } catch (e) {
            await this.handleError(e)
        }
    }

    public getDrivingSchool(): DrivingSchool {
        // User is instructor or admin of a driving school
        if (this.user?.drivingSchools?.[0]) {
            return this.user.drivingSchools[0]
        }

        // User is organization admin for a driving school
        if (this.user?.organizations?.[0]?.drivingSchools?.[0]) {
            return this.user.organizations[0].drivingSchools[0]
        }

        return this.user?.instructor?.drivingSchools[0]
    }

    public getDrivingSchools(): DrivingSchool[] {
        // User is instructor or admin of a driving school
        if (this.user?.drivingSchools?.[0]) {
            return this.user?.drivingSchools
        }

        // User is organization admin for a driving school
        if (this.user?.organizations?.[0]?.drivingSchools?.length) {
            return this.user?.organizations?.[0]?.drivingSchools
        }

        return this.user?.instructor?.drivingSchools
    }

    public async verifyEmail(input: VerifyEmailInput): Promise<VerifyEmailMutation> {
        try {
            const response = await firstValueFrom(
                this.verifyEmailMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async resendEmailVerificationLink(showMessage = false): Promise<ResendEmailVerificationLinkMutation> {
        try {
            const response = await firstValueFrom(
                this.resendEmailVerificationLinkService.mutate(),
            )

            if (showMessage) {
                const alert = await this.alertController.create({
                    message: this.translateService
                        .instant('pages.auth.verifyEmail.emailValidationRequiredMessage'),
                    buttons: [
                        {
                            text: this.translateService.instant('common.ok'),
                            handler: () => this.router.navigateByUrl('/auth/index'),
                        },
                    ],
                })
                await alert.present()
            }

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async hasRole(role: RoleEnum): Promise<boolean> {
        await this.initialize()
        return !! this.user?.roles.find((userRole) => userRole.name === role)
    }

    public async isMe(user: User): Promise<boolean> {
        if (! this.user) {
            await this.getUser()
        }
        return this.user.id === user.id
    }

    public async getPrimaryRole(): Promise<RoleEnum> {
        await this.initialize()
        return this.primaryRole
    }

    private async handleError(e): Promise<void> {
        // User email needs to be verified
        if (e.message.includes('verified') && ! (await this.alertController.getTop())) {
            this.userRequiresEmailVerification = true
            const alert = await this.alertController.create({
                backdropDismiss: false,
                message: this.translateService.instant('api.emailNotVerifiedMessage'),
                buttons: [
                    {
                        text: this.translateService.instant('api.emailNotVerifiedResend'),
                        handler: () => this.resendEmailVerificationLink(true),
                    },
                    {
                        text: this.translateService.instant('common.ok'),
                    },
                ],
            })
            await alert.present()

            // Other error: show a toast message
        } else {
            await this.apiHelperService.showHttpError()
        }
    }

}
