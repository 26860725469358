import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-student-add-product',
    templateUrl: './student-add-product.modal.html',
    styleUrls: ['./student-add-product.modal.scss'],
})
export class StudentAddProductModal extends BaseModal {

    @Input()
    public student: any // @TODO: Set type

}
