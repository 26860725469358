import { nl } from 'date-fns/locale'

import { AppConfigInterface } from '@app-config/app/app-config.interface'

// noinspection JSUnusedGlobalSymbols
export const AppConfig: AppConfigInterface = {

    environment: 'staging',

    locales: [
        nl,
    ],

    api: {
        graphQLEndpoint: 'https://api.staging.metmax.io/graphql',
        wsGraphQLEndpoint: 'wss://api.staging.metmax.io/graphql',
        clientId: '80230fdc-f9d1-4fe1-8d44-283e8c2cb53e',
    },

    cache: {
        cacheByDefault: true,
        ttl: 60, // In seconds
    },

    bugsnag: {
        enabled: true,
        apiKey: '7d827a5cf57b4a9fa86ab9c819cdaac7',
    },

}
