import { Component, ElementRef, Input, ViewChild } from '@angular/core'

import { Video } from '@app-graphql'

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {

    @Input()
    public src?: string

    @Input()
    public poster?: string

    @Input()
    public video?: Partial<Video>

    @Input()
    public aspectRatio?: string = '9/16'

    @Input()
    public autoplay?: boolean

    @Input()
    public playsInline?: boolean = true

    @ViewChild('videoElement')
    public videoElement: ElementRef<HTMLVideoElement>

    public origin = window.location?.origin

}
