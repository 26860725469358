import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-image-viewer-modal',
    templateUrl: './image-viewer-modal.component.html',
    styleUrls: ['./image-viewer-modal.component.scss'],
})
export class ImageViewerModalComponent extends BaseModal {

    @Input()
    public images: string[]

    @Input()
    public title?: string

}
