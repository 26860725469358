import { registerLocaleData } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import localeNl from '@angular/common/locales/nl'
import { NgModule, isDevMode, ErrorHandler } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import { CacheModule } from 'ionic-cache'
import { LottieModule } from 'ngx-lottie'
import lottiePlayer from 'lottie-web'

import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import Bugsnag from '@bugsnag/js'

import { AppConfig } from '@app-config'
import { CordovaModule } from '@app-modules/native/cordova.module'
import { GraphQLModule } from '@app-modules/shared/graphql.module'
import { NativeServiceModule } from '@app-services/native/native-service.module'
import { StorageServiceModule } from '@app-services/storage/storage-service.module'
import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from '@app/app.component'

import PACKAGE from '@project-root/package.json'

/**
 * i18n
 */
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http)
}

registerLocaleData(localeNl, 'nl')

/**
 * Lottie animations
 */
export function lottiePlayerFactory() {
    return lottiePlayer
}

/**
 * Bugsnag
 */
const errorHandlerProviders = []

if (AppConfig.bugsnag?.enabled && location.hostname !== 'localhost') {
    function errorHandlerFactory() {
        return new BugsnagErrorHandler()
    }

    Bugsnag.start({
        apiKey: AppConfig.bugsnag.apiKey,
        appVersion: (AppConfig.environment === 'production') ? PACKAGE.version : 'staging',
        appType: (window as any).cordova ? 'app' : 'browser',
        redactedKeys: [
            'accessToken',
            'refreshToken',
            'token',
            'password',
            'currentPassword',
        ],
        onError: (event) =>
            ! event.originalError.message?.includes('ApolloError')
            && ! event.originalError.message?.includes('The refresh token is invalid'),
    })

    errorHandlerProviders.push({
        provide: ErrorHandler,
        useFactory: errorHandlerFactory,
    })
}

/**
 * App module
 */
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CacheModule.forRoot(),
        CordovaModule,
        GraphQLModule,
        HttpClientModule,
        IonicModule.forRoot({
            mode: (/(iphone|ipad|ipod|macintosh|mac os)/i).test(navigator.userAgent) ? 'ios' : 'md',
        }),
        IonicStorageModule.forRoot({
            name: 'metmax',
        }),
        LottieModule.forRoot({ player: lottiePlayerFactory }),
        NativeServiceModule,
        StorageServiceModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: ! isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        ...errorHandlerProviders,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        HttpClientModule,
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
}
