import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import { Color } from '@ionic/core'
import { TranslateService } from '@ngx-translate/core'
import { Locale } from 'date-fns'

import { StorageService } from '@app-services'
import { AppConfig } from '@app-config'

@Component({
    selector: 'app-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent {

    @Input()
    public color?: Color | string

    @Input()
    public showLabel = true

    @Output()
    public localeChanged: EventEmitter<Locale> = new EventEmitter<Locale>()

    public locales = AppConfig.locales

    constructor(
        public translateService: TranslateService,
        protected storageService: StorageService,
    ) {
    }

    public async applyLanguage(code: string): Promise<void> {
        this.translateService.use(code)
        await this.storageService.set('locale', code)

        const locale = this.locales.find((l: Locale) => l.code === code)
        this.localeChanged.emit(locale)
    }

}
