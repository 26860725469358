import { Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core'
import { Platform } from '@ionic/angular'
import { ZXingScannerComponent } from '@zxing/ngx-scanner'

import { BaseModal } from '@app-components/modals/base.modal'

interface QrScannerCamera {
    name: string;
    id: string;
    device?: MediaDeviceInfo;
}

interface NativeQrScannerStatus {
    authorized: boolean;
    denied: boolean;
    restricted: boolean;
    prepared: boolean;
    scanning: boolean;
    previewing: boolean;
    showing: boolean;
    lightEnabled: boolean;
    canOpenSettings: boolean;
    canEnableLight: boolean;
    canChangeCamera: boolean;
    currentCamera: number;
}

interface NativeQrScannerError {
    code: number;
    name: string;
    _message: string;
}

interface NativeQrScanner {
    show: (callback?: () => void) => void;
    hide: (callback?: () => void) => void;
    scan: (callback?: (error: NativeQrScannerError | null, result: string | null) => void) => void;
    destroy: (callback?: () => void) => void;
    useFrontCamera: (callback?: () => void) => void;
    useBackCamera: (callback?: () => void) => void;
    getStatus: (callback?: (status: NativeQrScannerStatus) => void) => void;
    openSettings: (callback?: () => void) => void;
}

declare var QRScanner: NativeQrScanner

@Component({
    selector: 'app-qr-scanner-modal',
    templateUrl: './qr-scanner-modal.component.html',
    styleUrls: ['./qr-scanner-modal.component.scss'],
})
export class QrScannerModalComponent extends BaseModal implements OnDestroy {

    @Input()
    public title?: string

    @Input()
    public autoStart = true

    @Output()
    public scanCompleted = new EventEmitter<string>()

    @ViewChild('browserScanner', { static: false })
    public browserScanner: ZXingScannerComponent

    public camera: QrScannerCamera
    public availableCameras: QrScannerCamera[] = []

    public useBrowserScanner = false
    public scannerVisible = false
    public scannerPermissionAccepted = false
    public nativeScannerPermissionDenied = false

    constructor(
        private platform: Platform,
        private zone: NgZone,
    ) {
        super()

        this.useBrowserScanner = ! this.platform.is('hybrid')
        this.platform.ready().then(() => this.initializeNativeScanner())
        this.platform.resume.subscribe(() => this.initializeNativeScanner())
    }

    public ngOnDestroy(): void {
        this.stopScan()
    }

    public processScannedCode(code: string): void {
        this.scanCompleted.emit(code)
        this.stopScan()
    }

    public startScan(): void {
        document.documentElement.classList.add('qr-scanner-visible')
        this.scannerVisible = true
        if (! this.useBrowserScanner) {
            QRScanner.show(() => {
                QRScanner.scan((error, result: string) => {
                    if (error?.code) {
                        this.stopScan()
                        this.initializeNativeScanner()
                        return
                    }
                    this.zone.run(() => this.processScannedCode(result))
                })
            })
        }
    }

    public stopScan(): void {
        document.documentElement.classList.remove('qr-scanner-visible')
        this.scannerVisible = false
        if (! this.useBrowserScanner) {
            QRScanner.destroy()
        }
    }

    public handleDevices(devices: MediaDeviceInfo[]): void {
        this.availableCameras = devices.map((device) => ({
            id: device.deviceId,
            name: device.label,
            device,
        }))
        this.camera = this.availableCameras[0]
    }

    public handleDeviceChange(device: MediaDeviceInfo): void {
        if (device) {
            this.camera = this.availableCameras.find((c) => c.device.deviceId === device.deviceId)
        }
    }

    public setCamera(camera: QrScannerCamera | 'auto' = 'auto'): void {
        if (camera === 'auto') {
            if (! this.camera) {
                this.camera = this.availableCameras[0]
            }
        } else {
            this.camera = camera as QrScannerCamera
        }

        this.applyCameraDevice()
    }

    public toggleCamera(): void {
        if (this.availableCameras.length > 1) {
            this.setCamera(this.availableCameras.find((c) => c.id !== this.camera?.id))
        }
    }

    public openSettingsForManualPermission(): void {
        if (! this.useBrowserScanner) {
            QRScanner.openSettings()
        }
    }

    private initializeNativeScanner(): void {
        if (this.useBrowserScanner) {
            return
        }
        QRScanner.getStatus((status) => {
            this.nativeScannerPermissionDenied = status.denied && ! status.authorized
            this.scannerPermissionAccepted = status.authorized

            this.availableCameras = [
                { name: 'Rear Camera', id: 'rear', device: null },
            ]
            if (status.canChangeCamera) {
                this.availableCameras = [
                    ...this.availableCameras,
                    { name: 'Front Camera', id: 'front', device: null },
                ]
            }
            this.camera = this.availableCameras[0]
        })
    }

    private applyCameraDevice(): void {
        if (this.useBrowserScanner) {
            return
        }
        if (this.camera.id === 'front') {
            QRScanner.useFrontCamera()
        } else {
            QRScanner.useBackCamera()
        }
    }

}
